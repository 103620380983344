import React from "react";

import WithService from "../service/withService";

import { NewsEventSlider } from "../components";

const Resource = props => {
  // eslint-disable-next-line no-unused-vars
  const { loading, response, error } = props;

  if (response?.length) {
    return (
      <>
        {response.map(item => {
          return <div>{item.Title}</div>;
        })}
      </>
    );
  }

  return null;
};

const WorshipResources = props => {
  const { location } = props;

  const id = new URLSearchParams(location?.search).get("id");

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <WithService endpoint={`GetAllResourcesByType/${id}`}>
              <Resource />
            </WithService>
            <NewsEventSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorshipResources;
