import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import WorshipResources from "../container/worship-resources";

const WorshipResourcesPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Worship Resources" />
      <WorshipResources location={location} />
    </Layout>
  );
};

export default WorshipResourcesPage;
